import React, { useState } from "react";
import Navlist from "./navlist";
import navbarStyles from './navbarmobile.module.scss';

const NavbarMobile = (props) => {

    const [isActive, setActive] = useState(false);
    
    const burgerButton = () => {
        setActive(!isActive);  
    }; 

    props.open(isActive);
    
    return (
        <div className={`${navbarStyles.navbarMobile} ${isActive ? navbarStyles.navigation : ""}`} onClick={burgerButton}>
           <Navlist active={isActive} /> 
        </div>
    )
};

export default NavbarMobile;
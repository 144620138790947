import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "./header";
import Navbar from "./navbar";
import Footer from "./footer";
import layoutStyles from "./layout.module.scss";
import '../styles/index.scss';
import NavbarMobile from "./navbarmobile";


const Layout = (props) => {

  const [isOpen, setOpen] = useState(false);

  const navbarOpen = (open) => {
    setOpen(open);
  }

  return (
    <div>
      <Helmet>
        <title>Jane Doe</title>
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
      </Helmet>
      <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
        <NavbarMobile open={navbarOpen} />
          <div className={isOpen ? layoutStyles.openNavigation : null}>
            <Header />
            <Navbar />
            {props.children}
          </div>
        </div>
        <Footer />  
      </div>
    </div>
  )
};

export default Layout;
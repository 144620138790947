import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import footerStyle from "./footer.module.scss";
import InstaFeed from "./instagramFeed";

const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
        site{
            siteMetadata{
                author
                title
                created
                siteUrl
            }
        }
    }
    `)

    return (
        <div className={footerStyle.footer}>
            <InstaFeed />
            <div className={footerStyle.copyright}>
                <p className={footerStyle.text}>© 2021 <Link to="/">{data.site.siteMetadata.author}</Link>. All rights reserved. Designed and developed by <Link to="http://www.iamnat.dev">{data.site.siteMetadata.created}</Link></p>
            </div>
        </div>
    )
};

export default Footer;
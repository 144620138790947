import React from "react";
import headerStyles from "./header.module.scss";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";


const Header = () => {

    const data = useStaticQuery (graphql`
    query {
        contentfulHeader {
            title
            imageLogo {
                file {
                    fileName
                    url
                }
            }
        }
    }
    `)

    return (
        <div className={headerStyles.header}>
            <Link to="/" className={headerStyles.title}>
                <img className={headerStyles.image} src={data.contentfulHeader.imageLogo.file.url} alt="Jane Doe" />
            </Link>
        </div>
    )
};

export default Header;
import React, { useState } from "react";
import Navlist from "./navlist";
import navbarStyles from './navbar.module.scss';

const Navbar = () => {
    
    return (
        <div className={navbarStyles.navbar}>
            <Navlist />
        </div>
    )
};

export default Navbar;
import React from "react";
import { Link } from "gatsby";
import InstagramFeed from "react-ig-feed";
import 'react-ig-feed/dist/index.css';
import feedStyles from "./instagramFeed.module.scss"

const InstaFeed = () => {
    return (
        <div className={feedStyles.container}>
            <h3 className={feedStyles.title}>Find me on Instagram</h3>
            <p className={feedStyles.user}><Link to="https://www.instagram.com">@janedoe</Link></p>
            <InstagramFeed token={process.env.GATSBY_APP_INSTAGRAM}  counter="6"/>
        </div>

    )
}

export default InstaFeed;
import React from "react";
import { Link } from "gatsby";
import navbarStyles from './navlist.module.scss';

const Navlist = ({active}) => {

    return (
        <div>
            <nav>
                <ul className={active ? navbarStyles.navListNavigation : navbarStyles.navList}>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/">Home</Link></li>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/about">About</Link></li>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/courses">Courses</Link></li>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/testimonials">Testimonials</Link></li>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/events">Events</Link></li>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/blog">Articles</Link></li>
                    <li className={navbarStyles.navItem}><Link className={navbarStyles.navLink} activeClassName={navbarStyles.activeNavItem} to="/contact">Contact</Link></li>
                </ul>
            </nav>
        </div>
    )
 
};

export default Navlist;